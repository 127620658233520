footer {
    min-height: 350px;
    border-top: 1px dashed #026fad;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fafeff;
    font-family: 'Poppins', sans-serif;
}
.footer_logo {
    width: 95px;
    height: 25px;
}
.upper_footer {
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15%;
    gap: 10px;
}
.down_footer {
    width: 100%;
    height: 32px;
    background: linear-gradient(to right, #026fad, #026fad 50%, #2F98D3 90%);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.down_footer label {
    font-size: 11px;
    margin-bottom: 0px;
    opacity: 0.7;
}
.upper_footer_section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-right: 1px solid gainsboro;
}
.down_header_outer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.down_header_outer img {
    height: 50px;
    width: 90px;
}
.upper_footer_section:last-child {
    border-right: none;
}
.upper_footer_section label {
    font-weight: 400;
    font-size: 15px;
}
.upper_footer_section ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    list-style: none;
    gap: 7px;
}
li a, li {
    text-decoration: none;
    color: #000;
    font-size: 13px;
    font-weight: 300;
    text-align: left;
}
.language_wraper {
    display: flex;
    align-items: flex-start;
    gap: 7px;
}
.language_wraper a {
    text-decoration: none;
    color: #000;
    font-size: 13px;
    font-weight: 300;
    text-align: left;
}
.language_wraper img {
    height: 20px;
    width: 20px;
}
li a:hover {
    color: rgba(0, 0, 0, 0.514);
    text-decoration: none;
}
@media (max-width: 1000px) {
    .upper_footer {
        grid-template-columns: 19% 19% 19% 19% 19%;
        justify-content: space-evenly;
    }
  }
  @media (max-width: 750px) {
    .upper_footer {
        padding: 10px;
        gap: 0px;
    }
    .upper_footer_section {
        padding: 2px;
    }
  }
  /* @media (max-width: 650px) {
    footer {
        display: none;
    }
  } */
  @media (max-width: 600px) {
      .upper_footer {
          grid-template-columns: 50% 50%;
          justify-content: space-between;
      }
      .upper_footer_section {
          border-right: none;
          gap: 0px;
      }
    .language_wraper a {
        font-size: 11px;
    }
    li a {
        font-size: 11px;
    }
    .upper_footer_section label {
        font-weight: 400;
        font-size: 13px;
    }
    .footer_logo {
        height: 15;
        width: 57px;
        object-fit: contain;
    }
  }
 @media (max-width: 650px) {
    .upper_footer {
        /* grid-template-columns: 90%; */
        gap: 10px;
    }
  }