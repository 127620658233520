.payment_section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 40px 10px 20px 10px;
    min-height: 250px;
}
.payment_icons {
    height: 100px;
    width: 100px;
    filter: grayscale(100%);
}
.payment_icons:hover {
    filter: grayscale(0%);
}
@media (max-width: 850px) {
    .payment_icons {
        height: 75px;
        width: 75px;
    }
  }
  @media (max-width: 600px) {
    .payment_icons {
        height: 60px;
        width: 60px;
    }
  }
  @media (max-width: 410px) {
    .payment_icons {
        height: 50px;
        width: 50px;
    }
  }
  @media (max-width: 330px) {
    .payment_section {
        gap: 10px;
        flex-wrap: wrap;
        min-height: 100px;
    }
  }