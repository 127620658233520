.pricing_banner {
    height: 550px;
    position: relative;
}
.pricing_banner_background {
    height: 377px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0px;
    bottom: -1px;
    z-index: 111;
}
.pricing_details_wraper {
    z-index: 115;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    position: absolute;
    top: 60%;
    left: 10%;
    width: 80%;
}
.pricing_text_wraper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}
.pricing_text_wraper label {
    color: #026fad;
    font-weight: 200;
    margin-left: 10px;
    margin-top: -10px;
}
.pricing_text_wraper h1 {
    margin-bottom: 0px;
    font-size: 50px;
}
.pricing_details_box_wraper {
    z-index: 114;

    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    position: absolute;
    top: 0%;
    left: 27.5%;
    width: 45%;
    border-radius: 40px;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.452);
}
.pricing_details_box_numbers {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    font-family: 'Poppins', sans-serif;
}
.bolded_label {
    font-weight: bold;
    font-size: 35px;
}
.not_bolded_label {
    font-weight: 200;
    margin-top: 7px;
    
}
.pricing_details_box {
    width: 100%;
    z-index: 114;
    background-color: #fff;
    border-radius: 40px;
}
.title_of_the_box {
    padding: 10px;
    font-weight: 200;
    color: gray;
    font-family: 'Poppins', sans-serif;
}
.pricing_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 15px;
    background: linear-gradient(to right, #026fad, #026fad 50%, #2F98D3 90%);
    border: none;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    color: #fff;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    


}
.pricing_button:hover {
    background: unset;
    color: #026fad;
    transition:  color 1s, background-color 2s ease-out;;
    /* transition: scale 0.3s */

}
/* .pricing_button:hover span{
    transform: scale(1.1);
    transition:  transform 0.2s;
} */
.pricing_box_list {
    font-family: 'Poppins', sans-serif;
    padding: 10px 10px 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.pricing_box_list_item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}
.pricing_box_list_item label {
    font-weight: 400;
    text-align: left;
}
.pricing_banner_bc {
    z-index: 114;

    position: absolute;
    /* left: 22.5%; */
    /* right: 0; */
    top: 25%;
    width: auto;
    z-index: 113;
}

@media (max-width: 1025px) {
    .pricing_details_wraper {
        width: 90%;
        left: 5%;
    }
    .pricing_details_box_wraper {
        width: 55%;
        left: 22.5%;
    }
    .pricing_text_wraper h1 {
        font-size: 35px;
    }
    .pricing_text_wraper label {
        font-size: 13px;
        margin-top: -5px;
        margin-left: 3px;
    }
    .pricing_banner_bc {
        /* top: 70%; */
        width: 200%;
    }
  }
  @media (max-width: 900px) {
    .pricing_banner_background {
        height: auto;
        object-position: left;
    }
    .pricing_details_wraper {
        top: 30%;
    }
    .pricing_text_wraper h1 {
        color: #fff;
    }
    .pricing_text_wraper label {
        color: #fff;
    }
    .pricing_box_list_item label {
        font-size: 13px;
    }
    .pricing_banner_bc {
        top: 40%;
    }
  }
  @media (max-width: 650px) {
    .pricing_box_list {
        padding: 10px;
    }
    .pricing_box_list_item {
        align-items: flex-start;
    }
    .pricing_box_list_item label {
        font-size: 11px;
        margin-top: 4px;
    }
    .pricing_details_box_wraper {
        top: 100%;
        width: 80%;
        left: 10%;
    }
    .pricing_banner_bc {
        /* top: 70%; */
        width: 130%;
        /* left: 0; */
    }
  }
  @media (max-width: 500px) {
    .pricing_details_wraper {
        top: 20%;
    }
    .pricing_details_box_wraper {
        width: 95%;
        left: 2.5%;
    }
    .pricing_banner_bc {
        top: 65%;
        width: 100%;
    }
  }
  @media (max-width: 357px) {
    .title_of_the_box {
        font-size: 13px;
    }
    .bolded_label {
        font-size: 25px;
    }
    .not_bolded_label {
        font-size: 11px;
        margin-top: 5px;
    }
    .pricing_banner_bc {
        top: 80%;
    }
  }
  @media (max-width: 250px) {
    .pricing_banner_bc {
        top: 90%;
    }
  }