.developer_friendly {
    display: grid;
    grid-template-columns: 45% 50%;
    align-items: center;
    justify-content: flex-end;
    padding: 40px 10px 10px 10px;
    width: 100%;
    gap: 10px;
    background-color: #fafeff;
}
.developer_friendly .right_side {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.developer_friendly .right_side p {
    text-align: left;
}

.universe {
    width: 100%;
    max-height: 726px
}
.delay-10 {
    animation-duration: 4s !important;
}
.delay-15 {
    /* animation-delay: 15s !important; */
    animation-duration: 10s !important;
    animation-direction: reverse !important;
}
.delay-20 {
    /* animation-delay: 15s !important; */
    animation-duration: 7s !important;
}
.delay-0 {
    /* animation-delay: 15s !important; */
    animation-duration: 5s !important;
    animation-direction: reverse !important;
}
.delay-5 {
    /* animation-delay: 15s !important; */
    animation-duration: 5s !important;
    animation-direction: reverse !important;
}
@media (max-width: 420px) {
    .developer_friendly {
        grid-template-columns: 90%;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .developer_friendly .right_side {
        order: 2;
        text-align: center;
        align-items: center;
    }
    .developer_friendly .right_side p {
        text-align: center;
    }
  }