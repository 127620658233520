.desktop_header {
    display: grid;
    grid-template-columns: 20% 55% 20%;
    padding: 10px;
    align-content: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 10000;
}

.mobile_header {
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 101;
    padding: 10px;
    z-index: 10000;

}
.main_logo_wraper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_logo_wraper img {
    object-fit: cover;
}
.main_nav {
    list-style: none;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
}
.main_nav li button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}
/* .getStarted_btn {
    border: none;
    color: #155A96;
    background-color: #fff;
    box-shadow: 1px 0px 8px #80808057;
    height: 39px;
    width: 119px;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    margin: 0px 25px 15px;
} */
/* :active, :hover, :focus {
    outline: 0!important;
    outline-offset: 0;
  } */

  .btnH {
    position: relative;
    display: inline-block;
    color: #155A96;
    height: 39px;
    width: 119px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 0px;
  }
    .btnH span {         
      position: relative;
      display: inline-block;
      border: none;
      color: #fff;
      font-family: 'Poppins', sans-serif;
      top: 0; left: 0;
      width: 100%;
      transition: 0.3s;
    }
    .btn-1 span {
      display: flex;
      align-items: center; justify-content: center;
      height: 100%;
      width: 100%;
      border: none;
      color: #155A96;
      transition: 0.2s 0.1s;
    }
    .btn-2 span {
      display: flex;
      align-items: center; justify-content: center;
      height: 100%;
      width: 100%;
      border: none;
      color: #155A96;
      transition: 0.2s 0.1s;
    }
  @media screen and (min-width:900px){
    .btnH {
      background-color: transparent;
      color: #155A96;
    }
    :active, :hover, :focus {
      outline: 0!important;
      outline-offset: 0;
    }
    ::before,
    ::after {
      position: absolute;
      content: "";
    }
    .btn-1 span:hover {
      border: 1px solid #fff;
      color: #fff;
      transition: 0.1s 0.1s;
    }
    .btn-2 span:hover {
      border: 1px solid #155A96;
      color: #155A96;
      transition: 0.1s 0.1s;
    }
    .btnH.hover-filled-slide-down::before {
      bottom: 0; left: 0; right: 0; 
      height: 100%; width: 100%;
    }
    .btnH.hover-filled-slide-down:hover::before {
      height: 0%;
    }
    .btn-1 span {
      color: #155A96;
    }
    .btn-2 span {
      color: #fff;
    }
    }

  
  /*--- btn-1 ---*/
  .btn-1::before {
    background-color: #fff;
    border: none;
    border-radius: 5px;
    color: #155A96;
    transition: 0.3s ease-out;
  }
  .btn-2::before {
    background-color: #155A96;
    border: none;
    border-radius: 5px;
    color: #fff;
    transition: 0.3s ease-out;
  }
  


  /* 1.hover-filled-slide-down */

  
.main_nav li button,
.main_nav li button:after,
.main_nav li button:before {
  transition: all .5s;
}
/* .main_nav li button:hover {
  color: rgb(230, 230, 230);
} */
.main_nav li button,
.main_nav li button {
  position: relative;
}
.main_nav li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.softStyle:after,
.softStyle:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: rgb(255, 255, 255);
  height: 1px;
}
.dropdown-menu {
  z-index: 100000000;
  background-color: transparent;
  border: 1px solid #fff;

}
.dropdown-menu a {
  color: #fff;
  font-weight: 200;
}
.forceStyle:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #026fad;
    height: 1px;
  }
.main_nav li button:hover:after {
  width: 100%;
}
@media (max-width: 370px) {
  .mainLogo {
    width: 80px;
    height: 25px;
  }
}
@media (max-width: 425px) {
  .btn_header_mob {
    font-size: 11px;
  }
}
@media (max-width: 253px) {
  .btn_header_mob_wraper {
    display: none !important;
  }
  .mobile_header {
    display: flex !important;
    align-items: center;
  }
}
