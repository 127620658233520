.payment_solutions_content_wraper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
    text-align: left;
}
.payment_solutions_content_wraper p {
    opacity: 0.5;
    font-weight: 350;
}
.payment_solutions {
    display: grid;
    grid-template-columns: 50% 45%;
    align-items: center;
    padding: 40px 10px 10px 10px;
}
.payment_solutions_img {
    width: 100%;
}
@media (max-width: 1000px) {
    .payment_solutions_content_wraper h1 {
        font-size: 25px;
    }
    .payment_solutions_content_wraper p, .payment_solutions_content_wraper label {
        font-size: 12px;
    }
    .payment_solutions_content_wraper button span, .blueButtonWraper span {
        font-size: 11px;
        
    }
    .payment_solutions_content_wraper button, .blueButtonWraper {
        height: 30px !important;
    }
  }
  @media (max-width: 700px) {
    .payment_solutions_content_wraper h1 {
        font-size: 15px;
    }
    .payment_solutions_content_wraper p, .payment_solutions_content_wraper label {
        font-size: 10px;
    }
  }
  @media (max-width: 500px) {
    .payment_solutions_content_wraper h1 {
        font-size: 12px;
    }
    .payment_solutions_content_wraper p {
        font-size: 7px;
    }
  }
  @media (max-width: 420px) {
    .payment_solutions, .developer_friendly {
        grid-template-columns: 90%;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .payment_solutions_content_wraper {
        text-align: center;
        align-items: center;
    }
  }