.Banner {
    width: 100%;
    z-index: 99;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /* clip-path: url(#wave); */
}
.banner_section {
  z-index: 111;
}
@import url(//fonts.googleapis.com/css?family=Lato:300:400);
/* .Big_wave_banner {
    animation: move-forever 7s cubic-bezier(.55,.5,.45,.5)     infinite;
} */
.mobile_header {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  justify-content: center;
}
.start_section_title {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 65px;
    width: 60%;
    text-align: left;
    margin-bottom: 0px;
}
.start_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.banner_section {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
}
.banner_btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    width: 60%;
    gap: 20px;
}
.start_now_btn_pricing {
    border: none;
    border-radius: 30px;
    background-color: transparent;
    /* border: 1px solid #fff; */
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'Poppins', sans-serif;
}

.btn_background {
    position: absolute;
    left: 0px;
    right: 0px;
    z-index: 1;
}
.start_section_section {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 35px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 200;
    text-align: left;
    width: 60%;
}
.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}
.img_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.img_section_desktop {
    margin-left: -200px;
    /* margin-bottom: -88px; */
    z-index: 10;
    width: 100%;
    max-width: 810px;
}
.img_section_mob {
    z-index: 11;
    width: 100%;
    max-width: 860px;
    max-height: 540px;
    object-fit: cover;
}
.start_section_title_mobile {
  display: none;
}
/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 10s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 13s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 16s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 27s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 900px) {
  .waves {
    height:7vh;
  }
}
@media (max-width: 1600px) {
  .Banner {
    align-items: center;
  }
  .start_section_title {
    font-size: 55px;
  }
  .start_section_section {
    font-size: 30px;
  }
  .svg_img {
    top: -200px !important;
  }
}
@media (max-width: 1280px) {
  .banner_section {
    grid-template-columns: 45% 45%;
    justify-content: center;
  }
  /* .Banner {
    height: 70vh !important;
  } */
  .banner_btns {
    width: 90%;
  }
  .start_section_title {
    font-size: 45px;
    width: 90%;
  }
  .start_section_section {
    font-size: 20px;
    width: 90%;
  }
  .svg_img {
    top: -300px !important;
  }
}
@media (max-width: 900px) {
  .banner_section {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .img_section_mob {
    max-width: 550px;
    max-height: 400px;
    object-fit: contain;
    align-self: center;
  }
  .start_section_title {
    display: none;
  }
  .start_section_section {
    width: 100%;
    text-align: center;
  }
  .banner_btns {
    width: 100%;
    justify-content: center;
  }
  .start_section_title_mobile {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    align-self: center;
    font-size: 30px;
  }
  .img_section {
    width: 100%;
  }

}
@media (max-width: 380px) {
  .start_section_title_mobile {
    font-size: 20px;
  }
  .start_section_section {
    font-size: 15px;
  }
  /* .img_section_mob {
    height: 150px;
  } */
  .banner_btns {
    flex-direction: column;
  }
}
.wavy {
  animation: wave 120s linear infinite
}
@keyframes wave {
  0% {
    transform: translate3d(0,0,0);
  }
  100% {
    transform: translate3d(-50%,0,0);
  }
}