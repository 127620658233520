.whats_included {
    margin-top: 900px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 90px;
    padding: 40px;
}
.whats_included h1 {
    width: 90%;
    text-align: left;
}
.features_included {
    width: 100%;
    display: inline-grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 3px;
  justify-items: center;
  align-items: center;
  gap: 30px;
}
.feature_element {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    box-shadow: 1px 0px 8px #8080804d;
    border-radius: 30px;
    width: 70%;
    padding: 5px;
    max-width: 300px;
    min-height: 181.7px;
}
.feature_icon {
    height: 60px;
    width: 60px;
}
.feature_element p {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 13px;
}
@media (max-width: 1050px) {
    .whats_included {
        margin-top: 870px;
    }
    .whats_included h1 {
        font-size: 35px;
    }
  }
@media (max-width: 1000px) {
    .whats_included {
        margin-top: 800px;
    }
}
  @media (max-width: 940px) {
    .whats_included {
        margin-top: 750px;
    }
    
  }
@media (max-width: 900px) {
    .whats_included {
        margin-top: 600px;
        gap: 40px;
    }
    .features_included {
        grid-template-columns: 50% 50%;
        row-gap: 30px;
        column-gap: 0px;
    }
    .whats_included h1 {
        width: 100%;
    }
}
@media (max-width: 800px) {
    .whats_included {
        margin-top: 500px;
    }
}
@media (max-width: 730px) {
    .feature_element {
        min-height: 210px;
    }
}
@media (max-width: 700px) {
    .whats_included {
        margin-top: 400px;
    }
}
@media (max-width: 600px) {
    .whats_included {
        margin-top: 350px;
    }
    .feature_element {
        width: 90%;
    }
}
@media (max-width: 520px) {
    .whats_included {
        margin-top: 300px;
    }
}
@media (max-width: 500px) {
    .whats_included {
        margin-top: 252px;
    }
}
@media (max-width: 465px) {
    .feature_element p {
        font-size: 11px;
    }
    .whats_included h1 {
        font-size: 30px;
    }
}
@media (max-width: 300px) {
    .whats_included {
        margin-top: 260px;
    }
}
@media (max-width: 350px) {
    .features_included {
        grid-template-columns: 100%;
    }
}