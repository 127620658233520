.contact_sales {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 60px;
}
.contactSales_form {
    padding: 10px;
    width: 100%;
    z-index: 111;
    display: flex;
    /* grid-template-columns: 60%; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* gap: 50px; */
}
.contactSales_form h1 {
    /* margin-top: 100px; */
    opacity: 0.9;
    font-size: 40px;
    font-weight: 1000;
    color: #026fad;
}
.form_body {
    padding: 40px 10px 40px 10px;
    min-height: 500px;
    width: 50%;
    background-color: #fff;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.329);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form_body button {
    border: none;
    height: 35px;
    border-radius: 10px;
    background-color: #026fad;
    color: #fff;
    font-weight: 600;
    width: 30%;
    cursor: pointer;
    margin-top: 20px;
}
.form_group {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
}
.form_group input, .form_group select, #phone  {
    padding: 5px;
    border: none;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.185);
    border-radius: 10px;
}
#phone, .PhoneInput {
    width: 100%;
}
.form_group label {
    margin: 0px;
}
.Toastify__toast-container--top-right {
    top: 60px !important;
}
@media (max-width:1000px) {
    .form_body {
        width: 80%;
    }
  }
@media (max-width:900px) {
    .Toastify__toast-container--top-right {
        top: 60px !important;
    }
    .contactSales_form h1 {
        font-size: 35px;
    }
    .form_body button {
        width: 50%;
    }
  }
  @media (max-width:600px) {
    .form_body {
        width: 90%;
    } 
    .contactSales_form h1 {
        font-size: 25px;
    }
    
  }
  @media (max-width:375px) {
    .contact_sales {
        margin-top: 70px;
    }
  }