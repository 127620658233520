.left_side_friendly {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top, #fff ,  #97ccfb1c 40%,  #fff 90%), linear-gradient(to left, #fff ,  #97ccfb4d 60%,  #fff 90%);
    padding: 20px;
    /* margin-left: 150px; */
    gap: 40px;
}
.try_yourself {
    margin-top: 20px;
}
.friendly_section {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 50% 40%;
    gap: 50px;
}
.circles {
    width: 95%;
    position: absolute;
    right: -200px;
    z-index: -1;
}
.right_side_friendly {
    display: flex;
    align-items: flex-start;
    margin-right: -180px;
    background-repeat: no-repeat;
}
.text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 150px;
}
.text h1 {
    text-align: left;
}
.text label {
    text-align: left;
}
@media (max-width: 1150px) {
    .friendly_section {
        grid-template-columns: 60% 30%;
        gap: 20px;
    }
    .try_yourself {
        width: 15%;
    }
    .left_side_friendly {
        gap: 10px;
    }
    .circles {
        /* width: auto; */
        right: -69px;
    }
}
@media (max-width: 950px) {
    .text h1 {
        font-size: 25px;
        text-align: left;
    }
    .text label {
        font-size: 11px;
        text-align: left;
    }
    .circles {
        display: none;
    }
}
@media (max-width: 900px) {
    .right_side_friendly {
        background: unset !important;
        margin-right: 0px;

    }
    .friendly_section {
        grid-template-columns: 100%;
        padding: 20px;
    }
    .friendly_section .left_side_friendly {
        order: 2;
    }
}
@media (max-width: 500px) {
    .left_side_friendly {
        flex-direction: column;
        padding: 0px;
    }
    .try_yourself {
        transform: rotate(90deg);
    }
    .friendly_section {
        padding: 10px;
        gap: 5px;
    }
    .form-container {
        padding: 0px;
    }
}