.lets_get_started {
    min-height: 600px;
    padding: 40px 10px 10px 10px;
    display: grid;
    grid-template-columns: 40% 55%;
    align-items: center;
    justify-content: center;
}
.lets_get_started .right_side {
    padding: 5px;
    border-right: 1px solid rgba(128, 128, 128, 0.233);
}
.hr_mob {
    display: none;
}
.lets_get_started .right_side label {
    font-weight: 200;
}
.form_section_wraper {
    gap: 10px;
    display: flex;
    align-items: center;
}
.envlop_img {
    height: 120px;
    width: 120px;
}
.get_started_form {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 50%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
}
.first_part_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.form_input_wraper label {
    font-size: 13px;
}
.form_input_wraper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
}
.form_input_wraper input {
    height: 35px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 1px 4px #808080a3;
    -webkit-appearance: none;
    width: 100%; 
}
.second_part_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
}
.second_part_form textarea {
    width: 100%;
    min-height: 150px;
    border-radius: 10px;
    border: none;
    -webkit-appearance: none;
    box-shadow: 0px 1px 4px #808080a3;
}
@media (max-width: 1000px) {
    .envlop_img {
        height: 100px;
        width: 100px;
    }
    .form_input_wraper label {
        font-size: 11px;
    }
    .get_started_form {
        gap: 0px;
        grid-template-columns: 45% 50%;
    }
    .lets_get_started {
        grid-template-columns: 45% 55%;
    }
  }
@media (max-width: 640px) {
    .envlop_img {
        height: 70px;
        width: 91px;
    }
  }
  @media (max-width: 580px) {
    .get_started_form {
        grid-template-columns: 100%;
        gap: 20px;
    }
    .lets_get_started {
        grid-template-columns: 100%;
        gap: 20px;
    }
    .form_section_wraper {
        flex-direction: column;
    }
    .lets_get_started .right_side {
        border-right: none;
    }
    .lets_get_started .right_side {
        align-items: center;
    }
    .lets_get_started .right_side h1 {
        text-align: center;
    }
    .hr_mob {
        display: inline-block;
        width: 50%;
    }
  }