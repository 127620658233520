.growth {
    display: grid;
    grid-template-columns: 50% 40%;
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 59px;
    gap: 20px;
    position: relative;
}
.blueBC {
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0px;
    height: 200px;
    background-color: #97ccfb14;
}
/* .myElement {
    --animate-duration: 1s;
    --animate-delay: 2s;
    --animate-repeat: 2;
} */
.growth_content {
    z-index: 50;
    margin-top: 67px;
    /* width: 80%; */
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 0px 10px 10px 50px;
}
.growth_content h1 {
    /* font-weight: 800; */
    font-size: 45px;
}
.coloredSvg {
    /* height: 80%; */
    width: 90%;
}
@media (max-width: 1150px) {
    .growth_content h1 {
        font-size: 35px;
    }
    .coloredSvg {
        height: 100%;
    }
}
@media (max-width: 1150px) {
    .growth_content h1 {
        font-size: 25px;
    }
    .growth_content label {
        font-size: 15px;
    }
}
@media (max-width: 900px) {
    .growth_content {
        padding: 0px 10px 10px 10px;
    }
    .growth {
        grid-template-columns: 50% 50%;
    }
}
@media (max-width: 600px) {
    .growth_content {
        padding: 0px
    }
    .growth {
        grid-template-columns: 50% 50%;
    }
}
@media (max-width: 550px) {
    .growth {
        grid-template-columns: 100%;
    }
    .growth_content {
        order: 2;
    }
    .image_wraper h1 {
        display: inline-block !important;
        text-align: center;
        font-size: 25px;
    }
    .image_wraper {
        margin-top: 26px;
    }
    .growth_content h1 {
        display: none;
    }
    .growth_content label {
        text-align: center;
    }
    .coloredSvg {
        /* height: 300px; */
        width: 300px;
    }
}
@media (max-width: 311px) {
    .coloredSvg {
        /* height: 300px; */
        width: 250px;
    }
}