@media screen and (min-width:900px){
  .blueButtonWraper:active {
    transform: translateY(3px)
}
.blueButtonWraper::after, .blueButtonWraper::before { 
    border-radius: 30px;
}
.blueButtonWraper:hover {
    background: transparent;
    box-shadow: none;
    color: #026fad;
    // border: 1px solid #026fad;
  }
  .blueButtonWraper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s, border 0.3s
  }
  .blueButtonWraper:hover::before {
    opacity: 0
  }
  .blueButtonWraper::after {
    width: 100%;
    height: 40px;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent;
    border: 1px solid #026fad;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }
  
  .blueButtonWraper:hover::after {
    opacity: 1;
    transform: scaleX(1) scaleY(1)
  }

  .whiteButtonWraper:active {
    transform: translateY(3px)
}
.whiteButtonWraper::after, .whiteButtonWraper::before { 
    border-radius: 30px;
}
.whiteButtonWraper:hover {
    background: linear-gradient(to right, #026fad, #026fad 50%, #2F98D3 90%);
    box-shadow: none;
    color: #fff;
    // border: 1px solid #026fad;

  }
  
  .whiteButtonWraper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s, border 0.3s
  }

  .whiteButtonWraper:hover::before {
    opacity: 0
  }
  
  .whiteButtonWraper::after {
    width: 100%;
    height: 40px;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    background: linear-gradient(to right, #026fad, #026fad 50%, #2F98D3 90%);
    border: 1px solid #fff;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }
  
  .whiteButtonWraper:hover::after {
    opacity: 1;
    transform: scaleX(1) scaleY(1)
  }
}
.blueButtonWraper img {
    height: 17.41px;
    width: 17.41px;
    object-fit: cover;
}
.blueButtonWraper {
    /* width: 26%; */
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: linear-gradient(to right, #026fad, #026fad 50%, #2F98D3 90%);
    border-radius: 30px;
    border: none;
    box-shadow: 0px 1px 4px #808080a3;
    color: #fff;
    height: 40px;
    cursor: pointer;
    position: relative;
    transition: color 0.5s, transform 0.2s, background-color 0.2s;
    outline: none;

}


  /*-----------white button-----------*/

  .whiteButtonWraper img {
    height: 17.41px;
    width: 17.41px;
    object-fit: cover;
}
.whiteButtonWraper {
    /* width: 26%; */
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #fff;
    border-radius: 30px;
    border: none;
    box-shadow: 0px 1px 4px #808080a3;
    color: #026fad;
    height: 40px;
    cursor: pointer;
    position: relative;
    transition: color 0.5s, transform 0.2s, background-color 0.2s;
    outline: none;
}
