$offwhite: darken(white, 10%);
$darkblue: rgb(50,50,60);

.start_now_btn, .start_now_btn_inner {
  position: relative;
  color: white;
  height: 48px;
  width: 150px;
  border: none;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.start_now_btn_pricing {
  position: relative;
  color: white;
  height: 48px;
  width: 150px;
  border: 1px solid #fff;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.pricing_btn_black {
  position: relative;
  color: #000;
  height: 48px;
  width: 150px;
  border: none;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
}
.start_now_btn, .start_now_btn_inner {
  background: linear-gradient(to right, #026fad, #026fad 50%, #2F98D3 90%);
}

@media screen and (min-width:900px){
  .start_now_btn_pricing, .start_now_btn, .start_now_btn_inner, .pricing_btn_black {
    border: none;
  }
  .start_now_btn_pricing:active, .start_now_btn:active, .start_now_btn_inner:active, .pricing_btn_black:active {
    transform: translateY(3px)
  }
  .start_now_btn_pricing::after, .start_now_btn_pricing::before, .start_now_btn::after, .start_now_btn::before, .start_now_btn_inner::after, .start_now_btn_inner::before, .pricing_btn_black::after, .pricing_btn_black::before { 
    border-radius: 30px
  }
  .start_now_btn_pricing:active, .start_now_btn:active, .start_now_btn_inner:active, .pricing_btn_black:active {
    transform: translateY(3px)
  }
  
  .start_now_btn_pricing::after, .start_now_btn_pricing::before, .start_now_btn::after, .start_now_btn::before, .start_now_btn_inner::after, .start_now_btn_inner::before, .pricing_btn_black::after, .pricing_btn_black::before { 
    border-radius: 30px
  }
  .start_now_btn_pricing:hover, .start_now_btn:hover {
    background: transparent;
    box-shadow: none;
    color: $offwhite
  }
  .start_now_btn_inner:hover {
    background: transparent;
    box-shadow: none;
    color: #000
  }
  .pricing_btn_black:hover {
    background: transparent;
    box-shadow: none;
    color: #026fad
  }
  .start_now_btn_pricing::before, .start_now_btn::before, .start_now_btn_inner::before, .pricing_btn_black::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 150px;
    transition: opacity 0.3s, border 0.3s
  }

  .start_now_btn_pricing:hover::before, .start_now_btn:hover::before, .start_now_btn_inner:hover::before, .pricing_btn_black:hover::before {
    opacity: 0
  }
  
  .start_now_btn_pricing::after, .start_now_btn::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 47px;
    width: 149px;
    background-color: transparent;
    border: 1px solid $offwhite;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }
  .pricing_btn_black::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 47px;
    width: 149px;
    background-color: transparent;
    border: 1px solid #026fad;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }
  .start_now_btn_inner::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 47px;
    width: 149px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }
  .start_now_btn_pricing:hover::after, .start_now_btn:hover::after, .start_now_btn_inner:hover::after, .pricing_btn_black:hover::after {
    opacity: 1;
    transform: scaleX(1) scaleY(1)
  }
}
// .start_now_btn {
//   background-color: transparent;
//   color: darken($darkblue, 10%)
// }
  
 
#gradient-canvas {
  --gradient-color-1: #6ec3f4;
  --gradient-color-2: #2f98d3;
  --gradient-color-3: #2F98D3;
  --gradient-color-4: #2784b9;
}