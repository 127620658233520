.easySection {
    display: grid;
    grid-template-columns: 40% 50%;
    align-items: center;
    justify-content: center;
    gap: 20px;
    min-height: 600px;
    margin-top: 100px;
    padding: 20px;
}
.last_section {
    width: 100%;
}
.text_easy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.text_easy h1 {
    text-align: left;
}
.text_easy label {
    text-align: left;
}
@media (max-width: 900px) {
    .easySection {
        grid-template-columns: 100%;
    }
    .text_easy h1 {
        font-size: 25px;
    }
    .text_easy label {
        font-size: 11px;
    }
}
@media (max-width: 950px) {
    .easySection {
        min-height: unset;
        margin-top: 0px;

    }
    
}